import React, { useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';
import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
//teste

import Dashboard from './components/Dashboard';
// import Dashboard from './pages/Tratamentos';

import Empresas from './pages/Empresas';
import Unidades from './pages/Unidades';
import Departamentos from './pages/Departamentos';
import Cargos from './pages/Cargos';
import CentrosDeCustos from './pages/CentrosDeCustos';
import Motivos from './pages/Motivos';
import Eventos from './pages/Eventos';
import Feriados from './pages/Feriados';
import Jornadas from './pages/Jornadas';
import Colaboradores from './pages/Colaboradores';
import Tratamentos from './pages/Tratamentos';
import RelatorioEmpresas from './pages/RelatorioEmpresas';
import RelatorioUnidades from './pages/RelatorioUnidades';
import RelatorioDepartamentos from './pages/RelatorioDepartamentos';
import RelatorioCargos from './pages/RelatorioCargos';
import RelatorioCentroDeCusto from './pages/RelatorioCentroDeCusto';
import RelatorioEvento from './pages/RelatorioEvento';
import RelatorioFeriado from './pages/RelatorioFeriado';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import RelatorioMotivos from './pages/RelatorioMotivos';
import RelatorioEspelhoPonto from './pages/RelatorioEspelhoPonto';
import ImportacaoRep from './pages/ImportacaoRep';
import ConfiguracaoHorasExtras from './pages/ConfiguracaoHorasExtras';
import ConfiguracaoHorasExtrasEspecificas from './pages/ConfiguracaoHorasExtrasEspecificas';
import ConfiguracaoHorasExtrasIntrajornada from './pages/ConfiguracaoHorasExtrasIntrajornada';
import ConfiguracaoEspelhoPonto from './pages/ConfiguracaoEspelhoPonto';
import ConfiguracaoAdicionalNoturno from './pages/ConfiguracaoAdicionalNoturno';
import ConfiguracaoCadastroRep from './pages/ConfiguracaoCadastroRep';
import ConfiguracaoExportaFolha from './pages/ConfiguracaoExportaFolha';
import ConfiguracaoExtras from './pages/ConfiguracaoExtras';
import ConfiguracaoCodigoEventos from './pages/ConfiguracaoCodigoEventos';
import RelatorioSaldoHorasTrabalhadas from './pages/RelatorioSaldoHorasTrabalhadas';
import RelatorioColaboradores from './pages/RelatorioColaboradores';
import RelatorioFaltasPeriodo from './pages/RelatorioFaltasPeriodo';
import EscalasDeTrabalho from './pages/EscalasDeTrabalho';
import Justificativas from './pages/Justificativas';
import RelatorioMteEspelhoPonto from './pages/RelatorioMteEspelhoPonto';
import ArquivoMteAfdt from './pages/ArquivoMteAfdt';
import ArquivoMteAcjef from './pages/ArquivoMteAcjef';
import { PrivateRoute } from './utilities/PrivateRoute';
import BatidaViaLinkPermissao from './pages/BatidaViaLinkPermissao';
import BatidaViaLinkLocalizacao from './pages/BatidaViaLinkLocalizacao';
import BatidaViaLinkManutencao from './pages/BatidaViaLinkManutencao';
import PerfilContaUsuario from './pages/PerfilContaUsuarioConfiguracoes';
import PerfilContaUsuarioConfiguracoes from './pages/PerfilContaUsuarioConfiguracoes';
import PerfilContaUsuarioAssinatura from './pages/PerfilContaUsuarioAssinatura';
import ArquivoMteAfdBatidaViaLink from './pages/ArquivoMteAfdBatidaViaLink';
import ArquivoMteAej from './pages/ArquivoMteAej';
import RelatorioEspelhoPontoPorPeriodo from './pages/RelatorioEspelhoPontoPorPeriodo';
import RelatorioNotificacoesAjustes from './pages/RelatorioNotificacoesAjustes';
import RelatorioResumoEspelhoPonto from './pages/RelatorioResumoEspelhoPonto';
import BHSindicatos from './pages/BHSindicatos';
import BHPeriodoCompetencia from './pages/BHPeriodoCompetencia';
import BHAtivarColaboradores from './pages/BHAtivarColaboradores';
import BHIniciarSaldo from './pages/BHIniciarSaldo';
import BHIMovimentoHoras from './pages/BHIMovimentoHoras';
import BHMesVigente from './pages/BHMesVigente';
import RelatorioEspelhoPontoBH from './pages/RelatorioEspelhoPontoBH';
import RelatorioEspelhoPontoPorPeriodoBH from './pages/RelatorioEspelhoPontoPorPeriodoBH';
import RelatorioSaldoAcumuladoBH from './pages/RelatorioSaldoAcumuladoBH';
import RelatorioColaboradoresIniciadosBH from './pages/RelatorioColaboradoresIniciadosBH';
import FechamentoMes from './pages/FechamentoMes';
import LeitorFacialPermissao from './pages/LeitorFacialPermissao';


const App = () => {

    const [layoutMode, setLayoutMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('indigo');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [scheme, setScheme] = useState('light');
    const copyTooltipRef = useRef();
    const location = useLocation();

    let menuClick;
    let topbarItemClick;

    const menu = [
        {
            label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/',
        },
        {
            label: 'Regras', icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Configurações', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Espelho de Ponto', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/espelhoPonto' },
                        {
                            label: 'Horas Extras', icon: 'pi pi-fw pi-home',
                            items: [
                                { label: 'Gerais', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/horasExtras/gerais' },
                                { label: 'Específicas', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/horasExtras/especificas' },
                                { label: 'Intrajornada', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/horasExtras/intrajornada' },
                            ]
                        },
                        { label: 'Adicional Noturno', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/adicionalNoturno' },
                        { label: 'Cadastro de REP', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/cadastroRep' },
                        { label: 'Exportação de Folha', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/exportacaoFolha' },
                        { label: 'Extras', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/extras' },
                        { label: 'Código de Eventos', icon: 'pi pi-fw pi-home', to: '/regras/configuracoes/codigoEventos' },
                    ]
                },
            ]
        },
        {
            label: 'Tabelas', icon: 'pi pi-fw pi-flag',
            items: [
                {
                    label: 'Básicos', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'Empresas', icon: 'pi pi-fw pi-home', to: '/tabelas/empresas' },
                        { label: 'Unidades', icon: 'pi pi-fw pi-home', to: '/tabelas/unidades' },
                        { label: 'Departamentos', icon: 'pi pi-fw pi-home', to: '/tabelas/departamentos' },
                        { label: 'Cargos', icon: 'pi pi-fw pi-home', to: '/tabelas/cargos' },
                        { label: 'Centros De Custos', icon: 'pi pi-fw pi-home', to: '/tabelas/centrosdecustos' },
                        { label: 'Motivos', icon: 'pi pi-fw pi-home', to: '/tabelas/motivos' },
                        { label: 'Eventos', icon: 'pi pi-fw pi-home', to: '/tabelas/eventos' },
                        { label: 'Feriados', icon: 'pi pi-fw pi-home', to: '/tabelas/feriados' },
                    ]
                },
                {
                    label: 'Relatórios', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'Empresas', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/empresas' },
                        { label: 'Unidades', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/unidades' },
                        { label: 'Departamentos', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/departamentos' },
                        { label: 'Cargos', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/cargos' },
                        { label: 'Centros De Custos', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/centrosDeCustos' },
                        { label: 'Eventos', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/eventos' },
                        { label: 'Feriados', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/feriados' },
                        { label: 'Motivos', icon: 'pi pi-fw pi-home', to: '/tabelas/relatorio/motivos' }
                    ]
                }
            ]
        },
        {
            label: 'Cadastros', icon: 'pi pi-fw pi-id-card',
            items: [
                { label: 'Jornadas', icon: 'pi pi-fw pi-home', to: '/cadastros/jornadas' },
                { label: 'Colaboradores', icon: 'pi pi-fw pi-home', to: '/cadastros/colaboradores' },
                {
                    label: 'Relatórios', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'Colaboradores', icon: 'pi pi-fw pi-home', to: '/cadastros/relatorio/colaboradores' }
                    ]
                }
            ]
        },
        {
            label: 'Manutenção', icon: 'pi pi-fw pi-exclamation-triangle',
            items: [
                { label: 'Tratamentos', icon: 'pi pi-fw pi-home', to: '/manutencao/tratamentos' },
                { label: 'Escalas de Trabalho', icon: 'pi pi-fw pi-home', to: '/manutencao/escalasDeTrabalho' },
                { label: 'Justificativas', icon: 'pi pi-fw pi-home', to: '/manutencao/justificativas' },
                { label: 'Batidas Via Link', icon: 'pi pi-fw pi-home', to: '/manutencao/batidasViaLink' },
                { label: 'Fechamento de Mês', icon: 'pi pi-fw pi-home', to: '/manutencao/fechamentoMes' }
            ]
        },
        {
            label: 'Relatórios', icon: 'pi pi-fw pi-file-pdf',
            items: [
                {
                    label: 'Espelho do Ponto', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'Mensal', icon: 'pi pi-fw pi-home', to: '/relatorios/espelho' },
                        { label: 'Por Período', icon: 'pi pi-fw pi-home', to: '/relatorios/espelho/porPeriodo' }
                    ]
                },

                { label: 'Saldo Horas Trabalhadas', icon: 'pi pi-fw pi-home', to: '/relatorios/saldoHorasTrabalhadas' },
                { label: 'Faltas no Período', icon: 'pi pi-fw pi-home', to: '/relatorios/faltasPeriodo' },
                { label: 'Notificação de Ajustes', icon: 'pi pi-fw pi-home', to: '/relatorios/notificacaoAjustes' },
                { label: 'Resumo Espelho Ponto', icon: 'pi pi-fw pi-home', to: '/relatorios/resumoEspelhoPonto' }
            ]
        },
        {
            label: 'Banco de Horas', icon: 'pi pi-fw pi-clock',
            items: [
                { label: 'Sindicatos', icon: 'pi pi-fw pi-home', to: '/bancoHoras/sindicatos' },
                { label: 'Período da Competência', icon: 'pi pi-fw pi-home', to: '/bancoHoras/periodoCompetencia' },
                { label: 'Ativar Colaboradores', icon: 'pi pi-fw pi-home', to: '/bancoHoras/ativarColaboradores' },
                { label: 'Iniciar Saldo', icon: 'pi pi-fw pi-home', to: '/bancoHoras/iniciarSaldo' },
                { label: 'Movimentação de Horas', icon: 'pi pi-fw pi-home', to: '/bancoHoras/movimentoHoras' },
                { label: 'Fechamento do Mês', icon: 'pi pi-fw pi-home', to: '/bancoHoras/mesVigente' },
                {
                    label: 'Relatórios', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        {
                            label: 'Espelho do Ponto', icon: 'pi pi-fw pi-plus-circle',
                            items: [
                                { label: 'Mensal', icon: 'pi pi-fw pi-home', to: '/bancoHoras/relatorios/espelho' },
                                { label: 'Por Período', icon: 'pi pi-fw pi-home', to: '/bancoHoras/relatorios/espelho/porPeriodo' }
                            ]
                        },
                        { label: 'Saldo Acumulado', icon: 'pi pi-fw pi-home', to: '/bancoHoras/relatorios/saldoAcumulado' },
                        { label: 'Cols. Iniciados', icon: 'pi pi-fw pi-home', to: '/bancoHoras/relatorios/colaboradoresIniciados' },
                    ]
                }
            ],

        },
        {
            label: 'Utilitários', icon: 'pi pi-fw pi-sync',
            items: [
                { label: 'Importação REP', icon: 'pi pi-fw pi-home', to: '/utilitarios/importacaoREP' },
                {
                    label: 'Batida Via Link', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'Permissão', icon: 'pi pi-fw pi-home', to: '/utilitarios/batidaViaLink/permissao' },
                        { label: 'Localização', icon: 'pi pi-fw pi-home', to: '/utilitarios/batidaViaLink/localizacao' }
                    ]
                },
                {
                    label: 'Leitor Facial', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'Permissão', icon: 'pi pi-fw pi-home', to: '/utilitarios/leitorFacial/permissao' }
                    ]
                }
            ]
        },
        {
            label: 'Área MTE', icon: 'pi pi-fw pi-file',
            items: [
                { label: 'Espelho de Ponto', icon: 'pi pi-fw pi-home', to: '/areaMTE/espelho' },
                { label: 'AFDT', icon: 'pi pi-fw pi-home', to: '/areaMTE/afdt' },
                { label: 'ACJEF', icon: 'pi pi-fw pi-home', to: '/areaMTE/acjef' },
                { label: 'AEJ', icon: 'pi pi-fw pi-home', to: '/areaMTE/aej' },
                {
                    label: 'Batida Via Link', icon: 'pi pi-fw pi-plus-circle',
                    items: [
                        { label: 'AFD', icon: 'pi pi-fw pi-home', to: '/areaMTE/batidaViaLink/afd' }
                    ]
                }
            ]
        },

        {
            label: 'TORNE-SE UM CLIENTE!', cor: '#6ebc3b', to: '/cliente/registro'
        },
    ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === 'overlay' && !isMobile()) {
            setOverlayMenuActive(prevState => !prevState);
        } else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevState => !prevState);
            else
                setStaticMenuMobileActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState);
    }

    const onDocumentClick = (event) => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu();
        }

        topbarItemClick = false;
        menuClick = false;
    }

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuDesktopInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
        else {
            return true;
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false)
    }

    const isMobile = () => {
        return window.innerWidth < 1025;
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    const isSlim = () => {
        return layoutMode === 'slim';
    }

    const changeMenuMode = (event) => {
        setLayoutMode(event.menuMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);
    }

    const onSchemeChange = (color) => {
        setScheme(color);
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        changeTheme(themeName + '-' + color);
        changeLogo(color);
    }

    const changeTheme = (theme) => {
        setThemeColor(theme.split('-')[0]);
        changeStyleSheetUrl('layout-css', theme, 'layout');
        changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    const onThemeChange = (theme) => {
        setThemeColor(theme)
        changeTheme(theme + '-' + scheme);
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const changeLogo = (scheme) => {
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const logoUrl = `assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.png`;

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-static-inactive': staticMenuDesktopInactive && layoutMode !== 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-overlay-active': overlayMenuActive,
        'p-input-filled': inputStyle === 'filled'
    });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() })

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />


            <AppTopbar
                topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-content">
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={layoutMode} active={menuActive} />
                    <div className="layout-menu-footer">
                        {/* <div className="layout-menu-footer-title">TASKS</div>

                        <div className="layout-menu-footer-content">
                            <ProgressBar value={50} showValue={false}></ProgressBar>
                            Today
                            <ProgressBar value={80} showValue={false}></ProgressBar>
                            Overall
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="layout-content">

                <AppBreadcrumb />

                <div className="layout-content-container">
                    {/* <Route path="/" exact render={() => <Dashboard colorMode={scheme} location={location} />} /> */}
                    <PrivateRoute path="/" exact component={Dashboard} />
                    <PrivateRoute path="/tabelas/empresas" component={Empresas} />
                    <PrivateRoute path="/tabelas/unidades" component={Unidades} />
                    <PrivateRoute path="/tabelas/departamentos" component={Departamentos} />
                    <PrivateRoute path="/tabelas/cargos" component={Cargos} />
                    <PrivateRoute path="/tabelas/centrosdecustos" component={CentrosDeCustos} />
                    <PrivateRoute path="/tabelas/motivos" component={Motivos} />
                    <PrivateRoute path="/tabelas/eventos" component={Eventos} />
                    <PrivateRoute path="/tabelas/feriados" component={Feriados} />
                    <PrivateRoute path="/cadastros/jornadas" component={Jornadas} />
                    <PrivateRoute path="/cadastros/colaboradores" component={Colaboradores} />
                    <PrivateRoute path="/manutencao/tratamentos" component={Tratamentos} />
                    <PrivateRoute path="/tabelas/relatorio/empresas" component={RelatorioEmpresas} />
                    <PrivateRoute path="/tabelas/relatorio/unidades" component={RelatorioUnidades} />
                    <PrivateRoute path="/tabelas/relatorio/departamentos" component={RelatorioDepartamentos} />
                    <PrivateRoute path="/tabelas/relatorio/cargos" component={RelatorioCargos} />
                    <PrivateRoute path="/tabelas/relatorio/centrosDeCustos" component={RelatorioCentroDeCusto} />
                    <PrivateRoute path="/tabelas/relatorio/eventos" component={RelatorioEvento} />
                    <PrivateRoute path="/tabelas/relatorio/feriados" component={RelatorioFeriado} />
                    <PrivateRoute path="/tabelas/relatorio/motivos" component={RelatorioMotivos} />
                    <PrivateRoute path="/relatorios/espelho" exact component={RelatorioEspelhoPonto} />
                    <PrivateRoute path="/utilitarios/importacaoREP" component={ImportacaoRep} />
                    <PrivateRoute path="/utilitarios/batidaViaLink/permissao" component={BatidaViaLinkPermissao} />
                    <PrivateRoute path="/utilitarios/batidaViaLink/localizacao" component={BatidaViaLinkLocalizacao} />
                    <PrivateRoute path="/utilitarios/leitorFacial/permissao" component={LeitorFacialPermissao} />
                    <PrivateRoute path="/regras/configuracoes/horasExtras/gerais" component={ConfiguracaoHorasExtras} />
                    <PrivateRoute path="/regras/configuracoes/horasExtras/especificas" component={ConfiguracaoHorasExtrasEspecificas} />
                    <PrivateRoute path="/regras/configuracoes/horasExtras/intrajornada" component={ConfiguracaoHorasExtrasIntrajornada} />
                    <PrivateRoute path="/regras/configuracoes/espelhoPonto" component={ConfiguracaoEspelhoPonto} />
                    <PrivateRoute path="/regras/configuracoes/adicionalNoturno" component={ConfiguracaoAdicionalNoturno} />
                    <PrivateRoute path="/regras/configuracoes/cadastroRep" component={ConfiguracaoCadastroRep} />
                    <PrivateRoute path="/regras/configuracoes/exportacaoFolha" component={ConfiguracaoExportaFolha} />
                    <PrivateRoute path="/regras/configuracoes/extras" component={ConfiguracaoExtras} />
                    <PrivateRoute path="/regras/configuracoes/codigoEventos" component={ConfiguracaoCodigoEventos} />
                    <PrivateRoute path="/relatorios/saldoHorasTrabalhadas" component={RelatorioSaldoHorasTrabalhadas} />
                    <PrivateRoute path="/cadastros/relatorio/colaboradores" component={RelatorioColaboradores} />
                    <PrivateRoute path="/relatorios/faltasPeriodo" component={RelatorioFaltasPeriodo} />
                    <PrivateRoute path="/relatorios/espelho/porPeriodo" component={RelatorioEspelhoPontoPorPeriodo} />
                    <PrivateRoute path="/relatorios/notificacaoAjustes" component={RelatorioNotificacoesAjustes} />
                    <PrivateRoute path="/relatorios/resumoEspelhoPonto" component={RelatorioResumoEspelhoPonto} />
                    <PrivateRoute path="/manutencao/escalasDeTrabalho" component={EscalasDeTrabalho} />
                    <PrivateRoute path="/manutencao/justificativas" component={Justificativas} />
                    <PrivateRoute path="/manutencao/batidasViaLink" component={BatidaViaLinkManutencao} />
                    <PrivateRoute path="/manutencao/fechamentoMes" component={FechamentoMes} />
                    <PrivateRoute path="/areaMTE/espelho" component={RelatorioMteEspelhoPonto} />
                    <PrivateRoute path="/areaMTE/afdt" component={ArquivoMteAfdt} />
                    <PrivateRoute path="/areaMTE/acjef" component={ArquivoMteAcjef} />
                    <PrivateRoute path="/areaMTE/aej" component={ArquivoMteAej} />
                    <PrivateRoute path="/areaMTE/batidaViaLink/afd" component={ArquivoMteAfdBatidaViaLink} />
                    <PrivateRoute path="/perfil/conta" exact component={PerfilContaUsuarioConfiguracoes} />
                    <PrivateRoute path="/perfil/conta/configuracoes" component={PerfilContaUsuarioConfiguracoes} />
                    <PrivateRoute path="/perfil/conta/assinatura" component={PerfilContaUsuarioAssinatura} />
                    <PrivateRoute path="/bancoHoras/sindicatos" component={BHSindicatos} />
                    <PrivateRoute path="/bancoHoras/periodoCompetencia" component={BHPeriodoCompetencia} />
                    <PrivateRoute path="/bancoHoras/ativarColaboradores" component={BHAtivarColaboradores} />
                    <PrivateRoute path="/bancoHoras/iniciarSaldo" component={BHIniciarSaldo} />
                    <PrivateRoute path="/bancoHoras/movimentoHoras" component={BHIMovimentoHoras} />
                    <PrivateRoute path="/bancoHoras/mesVigente" component={BHMesVigente} />
                    <PrivateRoute path="/bancoHoras/relatorios/espelho" exact component={RelatorioEspelhoPontoBH} />
                    <PrivateRoute path="/bancoHoras/relatorios/espelho/porPeriodo" component={RelatorioEspelhoPontoPorPeriodoBH} />
                    <PrivateRoute path="/bancoHoras/relatorios/saldoAcumulado" component={RelatorioSaldoAcumuladoBH} />
                    <PrivateRoute path="/bancoHoras/relatorios/colaboradoresIniciados" component={RelatorioColaboradoresIniciadosBH} />

                    {/* <Route path="/marcarPonto/:link" component={NotFound} /> */}
                </div>

                <AppFooter />

                {staticMenuMobileActive && <div className="layout-mask"></div>}
            </div>


            <AppConfig themeColor={themeColor} onThemeChange={onThemeChange}
                inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} changeMenuMode={changeMenuMode}
                ripple={ripple} onRippleChange={onRippleChange}
                scheme={scheme} onSchemeChange={onSchemeChange} />
        </div>
    );

}

export default App;
