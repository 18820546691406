import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useHistory } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import Mensagens from "../globais/Mensagens";
import api from "../service/api";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Calendar } from 'primereact/calendar';
import { addLocale, locale } from 'primereact/api';

export const LeitorFacialVisualizacao = () => {

	let emptyModel = {
		senha: '',
		repitaSenha: '',
	};

	addLocale('br', {
		firstDayOfWeek: 1,
		dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
		dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
		dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
		monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
		monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
		today: 'Hoje',
		clear: 'Limpar'
	});

	locale("br");

	let hoje = new Date();

	const history = useHistory();
	const [submitted, setSubmitted] = useState(false);
	const [model, setModel] = useState(emptyModel);
	const message = useRef();
	const messageDialog = useRef();
	const [colaborador, setColaborador] = useState(null);
	const [dataHora, setDataHora] = useState('');
	const [uuid, setUuid] = useState(null);
	const [presencaSenha, setPresencaSenha] = useState(true);
    const [senhaValida, setSenhaValida] = useState(false);
	const [leitorFacialDados, setLeitorFacialDados] = useState(null);
	const [displayConfirmation, setDisplayConfirmation] = useState(false);
	const [displaySenha, setDisplaySenha] = useState(false);
	const [displayPerdeuSenha, setDisplayPerdeuSenha] = useState(false);
	const [displayHistorico, setDisplayHistorico] = useState(false);
	const [linkHistorico, setLinkHistorico] = useState(null);
	const [dataInicial, setDataInicial] = useState(hoje);
	const [dataFinal, setDataFinal] = useState(hoje);

	useEffect(() => {

		// Obtenha a parte da URL após o caractere '#'
		const hash = window.location.hash;

		// Remova o caractere '#/' do início da string
		const link = hash.substring(2);

        // console.log('link', link)

		let uuidLink = link.split("visualizacao/")[1];

        // console.log('uuidLink', uuidLink)

		setUuid(uuidLink);

		let uuidDividido = uuidLink.split("-");

		let idCliente = uuidDividido[uuidDividido.length - 1];

		// cliente temporario
		const cliente = { "id": idCliente, "nomeCliente": "LeitorFacial" };
		localStorage.setItem("cliente", JSON.stringify(cliente));

		const usuario = { "token": "LeitorFacial", "username": "Indefinido" };
		localStorage.setItem("usuario", JSON.stringify(usuario));

        // console.log('idCliente', idCliente)

		api.get("/clientes/" + idCliente)
			.then(response => {

				// localStorage.setItem("cliente", JSON.stringify(response.data));

                    api.get("/leitorFacial/permissoes/" + uuidLink)
                    .then(response => {
                        if (!response.data) {
                            goNegado();
                        }

                        setLeitorFacialDados(response.data);
                        // console.log('colaborador', response.data.colaborador )
                        setColaborador(response.data.colaborador);

                        setPresencaSenha(response.data.presencaoSenha);

                        if (response.data.presencaoSenha === false) {
                            setDisplaySenha(true);
                        }

                        if(response.data.colaborador){
                            api.post("/afdEquipamento/colaborador/" + response?.data?.colaborador?.cpf + "/ultimoComprovante")
                            .then(response => {
                                let data = response.data.dataS;
                                let horario = response.data.horarioS;
                                setDataHora(data + ", " + horario);
                            })
                            .catch(() => {
                                console.log("aqui")

                                addMessage("error", Mensagens.ERRO);
                            })
                        }


                    })
                    .catch(() => {
                        addMessage("error", Mensagens.ERRO);
                    })
			})
			.catch(() => {
				addMessage("error", Mensagens.ERRO);
			})


	}, []);

	const onInputChange = (e, descricao) => {
		const val = (e.target && e.target.value) || '';
		let _model = { ...model };
		_model[`${descricao}`] = val;
		setModel(_model);
	}

	const confirmationDialogFooter = (
		<>
			<Button type="button" label="Não" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" autoFocus />
			<Button type="button" label="Sim" icon="pi pi-check" onClick={() => cadastrarSenha()} className="p-button-text" />
		</>
	)

	const perdeuSenhaDialogFooter = (
		<>
			<Button type="button" label="Entendi" icon="pi pi-thumbs-up" onClick={() => setDisplayPerdeuSenha(false)} className="p-button-text" autoFocus />
		</>
	)


	const addMessage = (sv, cn) => {
		message.current.show({ severity: sv, content: cn });
	};

	const addMessageDialog = (sv, cn) => {
		messageDialog.current.show({ severity: sv, content: cn });
	};


	const cadastrarSenha = () => {
		if (leitorFacialDados.id && model.senha.length > 5) {
			if (model.senha === model.repitaSenha) {
				api
					.put("/leitorFacial/permissoes/" + leitorFacialDados.id, {
						id: leitorFacialDados.id,
						senha: model.senha
					})
					.then(() => {
						// setModel(response.data);
						addMessage("success", Mensagens.SENHA_CRIADA);
						setPresencaSenha(true);
						setDisplayConfirmation(false);
						setDisplaySenha(false);
					})
					.catch(() => {
						addMessageDialog("error", Mensagens.ERRO);
					})
			} else {
				setDisplayConfirmation(false);
				addMessageDialog("error", Mensagens.SENHAS_DIFERENTES);
			}
		} else {
			setDisplayConfirmation(false);
			addMessageDialog("error", Mensagens.VERIFIQUE_SENHA);
		}
    }

	const historicoBatidas = () => {
		setSubmitted(true);
		if (dataInicial && dataFinal) {
			const diaInicial = dataInicial.getUTCDate();
			const mesInicial = dataInicial.getUTCMonth() + 1; // Os meses em JavaScript são base 0 (janeiro = 0, fevereiro = 1, ...)
			const anoInicial = dataInicial.getUTCFullYear();

			const dataInicialFormatada = `${diaInicial < 10 ? '0' : ''}${diaInicial}/${mesInicial < 10 ? '0' : ''}${mesInicial}/${anoInicial}`;

			const diaFinal = dataFinal.getUTCDate();
			const mesFinal = dataFinal.getUTCMonth() + 1; // Os meses em JavaScript são base 0 (janeiro = 0, fevereiro = 1, ...)
			const anoFinal = dataFinal.getUTCFullYear();

			const dataFinalFormatada = `${diaFinal < 10 ? '0' : ''}${diaFinal}/${mesFinal < 10 ? '0' : ''}${mesFinal}/${anoFinal}`;

			api
				.post("/afdEquipamento/colaborador/" + colaborador.id + "/historico", {
					colaborador: colaborador,
					dataInicialS: dataInicialFormatada,
					dataFinalS: dataFinalFormatada,
				}, { responseType: 'arraybuffer' })
				.then((response) => {
					const file = new Blob(
						[response.data],
						{ type: 'application/pdf' });

					const fileURL = URL.createObjectURL(file);

					setLinkHistorico(fileURL);

					window.open(fileURL);
					addMessage("success", Mensagens.OPERACAO_SUCESSO);

				})
				.catch(error => {
					if (error.response.status === 400) {
						addMessage("error", Mensagens.SENHA_ERRADA);
					} else {
						addMessage("error", Mensagens.ERRO);
					}
				})
			setSubmitted(false);
		}
	}

	const goNegado = () => {
		history.push('/access');
	}

	const goHistoricoBatidas = () => {
		setDisplayHistorico(true);
	}

    const goVerRecursos = () => {
        api.post("/leitorFacial/permissoes/verificarSenha", {
            id: leitorFacialDados.id,
            senha: model.senha
        })
        .then((response) => {
            setSenhaValida(true);
        })
        .catch(error => {
            // console.log('status', error.response.status)
            if (error.response.status === 401) {
                setSenhaValida(false);
                addMessage("error", Mensagens.SENHA_ERRADA);
            } else {
                setSenhaValida(false);
                addMessage("error", Mensagens.ERRO);
            }
        })

    }
	return (
		<div className="login-body">
			<div className="body-container">
            <h1 className="title">Olá, {colaborador?.nome}</h1>
				<div className="grid">
                <div className="col-12 lg:col-6 left-side">
                    <div>

                    </div>
                </div>
					<div className="col-12 lg:col-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
                            <span className="title">Digite sua senha</span>
								<div className="grid p-fluid">
									<div className="col-12">
										<Password placeholder="Senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senha')} required className={classNames({ 'p-invalid': submitted && !model.senha })} />
										{submitted && !model.senha && <small className="p-invalid">Senha obrigatória</small>}
									</div>

									<div className="col-6" style={{ display: presencaSenha === false ? ' ' : 'none' }}>
										<Button label="Cadastrar Senha" onClick={() => setDisplayConfirmation(true)} />
									</div>

									<div className="col-12 password-container">
										<button type="text" className="p-link" onClick={(e) => setDisplayPerdeuSenha(true)}>Perdeu a Senha?</button>
									</div>

                                    <div className="col-12" style={{ display: presencaSenha ? ' ' : 'none' }}>
										<Button className="p-button mr-2 mt-1 mb-1" label="Continuar" onClick={goVerRecursos} />
									</div>
{/*
									<div className="col-12" style={{ display: senhaValida ? ' ' : 'none' }}>
										<Button className="p-button-success mr-2 mt-1 mb-1" label="Comprovantes de Batidas" icon="pi pi-history" onClick={goHistoricoBatidas} />
									</div> */}

                                    <div className="col-12" style={{ display: senhaValida ? ' ' : 'none' }}>
										<label htmlFor="ultima-batida">{"Última Batida: " + dataHora}</label>
									</div>

                                    <div className="col-12" style={{ display: senhaValida ? ' ' : 'none' }}>
										<Button className="p-button-warning mr-2 mt-1 mb-1" label="Histórico de Batidas" icon="pi pi-history" onClick={goHistoricoBatidas} />
									</div>

									<div className="col-12">
										<Messages ref={message} />
									</div>

									<Dialog header="Confirmação" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)}
									style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
										<div className="flex align-items-center justify-content-center">
											<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
											<span>Guarde sua senha, será solicitada em todas as batidas. Tem certeza de que deseja continuar?</span>
										</div>
									</Dialog>

									<Dialog header="Perdeu a senha?" visible={displayPerdeuSenha} onHide={() => setDisplayPerdeuSenha(false)} style={{ width: '350px' }} modal footer={perdeuSenhaDialogFooter}>
										<div className="flex align-items-center justify-content-center">
											<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
											<span>Solicite atualização de senha para o responsável pelo Controle de Ponto.</span>
										</div>
									</Dialog>

									<Dialog header="Cadastrar Senha" visible={displaySenha} closable={false} maximizable={false} maximized={true} modal style={{ width: '50vw' }} >
										<div className="field grid mb-2">
											<div className="col-12 md:col-12">
											</div>
											<Panel className="col-12 md:col-12 mt-2" header={<strong>{colaborador?.nome}</strong>}>
												<div className="p-fluid formgrid grid">
													<div className="field col-12 md:col-2 mb-2">
														<Password placeholder="Senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senha')} required className={classNames({ 'p-invalid': submitted && !model.senha })} />
														{submitted && !model.senha && <small className="p-invalid">Senha obrigatória</small>}
													</div>
													<div className="field col-12 md:col-2 mb-2">
														<Password placeholder="Repita a senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'repitaSenha')} required className={classNames({ 'p-invalid': submitted && !model.repitaSenha })} />
														{submitted && !model.repitaSenha && <small className="p-invalid">Senha obrigatória</small>}
													</div>
													<div className="field col-12 md:col-2 mb-2">
														<Button label="Cadastrar" onClick={() => setDisplayConfirmation(true)} />
													</div>
													<div className="col-12">
														<Messages ref={messageDialog} />
													</div>
												</div>

											</Panel>
										</div>
									</Dialog>

									<Dialog header="Histórico de Batidas" visible={displayHistorico} onHide={() => setDisplayHistorico(false)} closable={true} maximizable={false} maximized={true} modal style={{ width: '50vw' }} >
										<div className="field grid mb-2">
											<div className="col-12 md:col-12">
											</div>
											<Panel className="col-12 md:col-12 mt-2" header={<strong>Período</strong>}>
												<div className="p-fluid formgrid grid">
													<label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
													<div className="field col-12 md:col-2 mb-2">
														<Calendar dateFormat="dd/mm/yy" value={dataInicial} onChange={(e) => setDataInicial(e.value)} monthNavigator yearNavigator yearRange="2010:2050" showIcon required className={classNames({ 'p-invalid': submitted && !dataInicial })}></Calendar>
														{submitted && !dataInicial && <small className="p-invalid">Data Inicial obrigatória</small>}
													</div>

													<label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
													<div className="field col-12 md:col-2 mb-2">
														<Calendar dateFormat="dd/mm/yy" value={dataFinal} onChange={(e) => setDataFinal(e.value)} monthNavigator yearNavigator yearRange="2010:2050" showIcon required className={classNames({ 'p-invalid': submitted && !dataFinal })}></Calendar>
														{submitted && !dataFinal && <small className="p-invalid">Data Final obrigatória</small>}
													</div>

													<div className="field col-12 md:col-2 mb-2">
														<Button label="Gerar" onClick={historicoBatidas} />
													</div>
												</div>
											</Panel>
										</div>
									</Dialog>

								</div>
							</div>

						</div>
					</div>

				</div>
			</div >
		</div >

	);


	// return (
	// 	<a href={imageUrl} target="_blank" rel="noopener noreferrer">
	// 		Abrir mapa do OpenStreetMap
	// 	</a>
	// );

	// return <iframe width="100%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Map" src={imageUrl} />;
}

