import React, { useEffect } from 'react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import App from "./App";
import { Login } from "./pages/Login";
import { Error } from "./pages/Error";
import { NotFound } from "./pages/NotFound";
import { Access } from "./pages/Access";
import { RegistroUsuario } from './pages/RegistroUsuario';
import { RegistroCliente } from './pages/RegistroCliente';
import { PrivateRoute } from './utilities/PrivateRoute';
import { MarcarPontoEfetuar } from './pages/MarcarPontoEfetuar';
import { LeitorFacialVisualizacao } from './pages/LeitorFacialVisualizacao';

const AppWrapper = (props) => {
	let location = useLocation();
	// console.log(props.location.pathname);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	if (props.location.pathname.startsWith("/marcar-ponto/")) {
		return <Route path="/marcar-ponto/:link" component={MarcarPontoEfetuar} />
	}

    if (props.location.pathname.startsWith("/leitor-facial/visualizacao")) {
		return <Route path="/leitor-facial/:link" component={LeitorFacialVisualizacao} />
	}

	switch (props.location.pathname) {
		case "/login":
			return <Route path="/login" component={Login} />
		case "/error":
			return <Route path="/error" component={Error} />
		case "/notfound":
			return <Route path="/notfound" component={NotFound} />
		case "/access":
			return <Route path="/access" component={Access} />
		case "/usuario/registro":
			return <Route path="/usuario/registro" component={RegistroUsuario} />
		case "/cliente/registro":
			return <PrivateRoute path="/cliente/registro" component={RegistroCliente} />
		case "/batida/efetuar":
			return <Route path="/batida/efetuar" component={MarcarPontoEfetuar} />
		default:
			return <App />;
	}

}

export default withRouter(AppWrapper);
